<template>
  <AppHeader
    display-back-button
    @next="nextStep"
    @back="previousStep"
  />
 </template>

<script setup lang="ts">
import type { IDocument } from '@einfachgast/shared';
import type { PropType } from 'vue';
import AppHeader from '@/components/AppHeader.vue';

const emit = defineEmits<{
  (e: 'confirm'): void,
  (e: 'back'): void}
>();

defineProps({
  document: {
    type: Object as PropType<IDocument>,
    required: true,
    default: () => ({} as IDocument),
  },
});
const nextStep = () => {
  emit('confirm');
};

const previousStep = () => {
  emit('back');
};
</script>
<template>
  <AppHeader
    display-back-button
    :has-error="hasError"
    @next="showDialog = true"
    @back="back"
  />
  <div class="document-confirmation">
    <v-dialog
      v-model="showDialog"
      :persistent="true"
      width="auto"
    >
      <v-card>
        <v-card-text>
          <div class="closer text-right mb-2">
            <v-icon
              :icon="mdiClose"
              :size="25"
              @click="onCancel"
            />
          </div>
          <div
            class="title mb-2"
            :class="{ 'text-red': hasError}"
          >
            {{ title }}
            <v-icon
              v-if="hasError"
              size="small"
              :icon="mdiAlertCircleOutline"
              color="red"
            />
          </div>
          <VueSignaturePad
            ref="signaturePad"
            class="has-dashed-border"
            height="250px"
            :options="{ onEnd: onSign, minDistance: 12 }"
            expanded
            data-qa="signature-pad"
          />
          <v-btn
            class="mt-8"
            color="success"
            size="x-large"
            variant="flat"
            block
            data-qa="confirm-signature-button"
            @click="onSubmit"
          >
            {{ $translate('signAndProceed') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
 </template>

<script setup lang="ts">
import { type PropType, ref, computed } from 'vue';
import type { IDocument } from '@einfachgast/shared';
import { VueSignaturePad } from 'vue-signature-pad';
import AppHeader from '@/components/AppHeader.vue';
import { mdiAlertCircleOutline, mdiClose } from '@mdi/js';
import { translate } from '@/plugins/translator';

const showDialog = ref(false);
const signature = ref('');
const signaturePad = ref<VueSignaturePad | null>(null);

const hasError = ref(false);
const props = defineProps({
  document: {
    type: Object as PropType<IDocument>,
    required: true,
    default: () => ({} as IDocument),
  },
});
const emit = defineEmits<{(e: 'confirm', value: string): void, (e: 'back'): void}>();
const title = computed(() => translate(props.document.confirmationCaption));

const onSubmit = () => {
  if (!signature.value) {
    hasError.value = true;
    return;
  }
  emit('confirm', signature.value);
  showDialog.value = false;
};

const onCancel = () => {
  signature.value = '';
  showDialog.value = false;
  hasError.value = false;
};

const onSign = () => {
  const result = signaturePad.value?.saveSignature();
  if (!result || result?.isEmpty) {
    signature.value = '';
    return;
  }
  signature.value = result.data as string;
};

const back = () => {
  signature.value = '';
  showDialog.value = false;
  hasError.value = false;
  emit('back');
};


</script>